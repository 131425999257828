import { useCallback, useEffect, useState } from 'react';
import { getRenderingEngine } from '@cornerstonejs/core';
import { DicomMetadataStore } from '../services';
import utils from '../utils';

/** ********************
 * CUSTOM UTILS
 ********************** */
const handleSingleMPR = (viewportGridService, displaySetService, openInNewWindow = false) => {
  try {
    const activeViewportId = viewportGridService.getState().activeViewportId;

    // Get the viewport details
    const viewportDetails = viewportGridService.getViewportState(activeViewportId);

    const displaySetInstanceUID = viewportDetails?.displaySetInstanceUIDs?.[0];

    const displaySet = displaySetService.getDisplaySetByUID(displaySetInstanceUID);

    const StudyInstanceUID = displaySet?.StudyInstanceUID;
    const SeriesInstanceUID = displaySet?.SeriesInstanceUID;
    const SeriesNumber = displaySet?.SeriesNumber;

    if (SeriesInstanceUID) {
      // Get URL search params
      // const urlParams = new URLSearchParams(window.location.search);
      const { taskId, studyId } = DicomMetadataStore.getTaskAndStudyIdForStudy(StudyInstanceUID);
      const blob = DicomMetadataStore?.getBlobForStudy(StudyInstanceUID);

      let url = '';

      // construct search params
      const params = {
        seriesUid: SeriesInstanceUID,
        seriesNumber: SeriesNumber,
        mpr: true,
        hide3D: true,
        hideLayout: true,
      };
      const searchParams = utils?.constructSearchParams(params);

      if (blob) {
        url = `${window.location.origin}${window.location.pathname}?blob=${blob}&${searchParams}`;
      } else if (taskId && studyId) {
        url = `${window.location.origin}${window.location.pathname}?tid=${taskId}&sid=${studyId}&${searchParams}`;
      } else {
        console.error(
          'No taskId or studyId found for StudyInstanceUID: ',
          SeriesInstanceUID,
          ' to open MPR mode in new window'
        );
      }

      if (openInNewWindow) {
        const width = Math.min(window.innerWidth * 0.95, 1600);
        const height = Math.min(window.innerHeight * 0.95, 1200);
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        // const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;

        // isoloating child window in more secure and intensive way
        const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,noopener,noreferrer`;
        window.open(url, '_blank', features);
      }
    }
  } catch (error) {
    console.error('while opening single MPR :', error);
  }
};

const handleSingle3D = (viewportGridService, displaySetService, openInNewWindow = false) => {
  try {
    const activeViewportId = viewportGridService.getState().activeViewportId;

    // Get the viewport details
    const viewportDetails = viewportGridService.getViewportState(activeViewportId);

    const displaySetInstanceUID = viewportDetails?.displaySetInstanceUIDs?.[0];

    const displaySet = displaySetService.getDisplaySetByUID(displaySetInstanceUID);

    const StudyInstanceUID = displaySet?.StudyInstanceUID;
    const SeriesInstanceUID = displaySet?.SeriesInstanceUID;
    const SeriesNumber = displaySet?.SeriesNumber;

    if (SeriesInstanceUID) {
      // Get URL search params
      // const urlParams = new URLSearchParams(window.location.search);
      const { taskId, studyId } = DicomMetadataStore.getTaskAndStudyIdForStudy(StudyInstanceUID);
      const blob = DicomMetadataStore?.getBlobForStudy(StudyInstanceUID);

      let url = '';
      const params = {
        seriesUid: SeriesInstanceUID,
        seriesNumber: SeriesNumber,
        only3D: true,
        hideMpr: true,
        hideLayout: true,
      };

      // construct search params
      const searchParams = utils?.constructSearchParams(params);

      if (blob) {
        url = `${window.location.origin}${window.location.pathname}?blob=${blob}&${searchParams}`;
      } else if (taskId && studyId) {
        url = `${window.location.origin}${window.location.pathname}?tid=${taskId}&sid=${studyId}&${searchParams}`;
      } else {
        console.error(
          'No taskId or studyId found for StudyInstanceUID: ',
          SeriesInstanceUID,
          ' to open MPR mode in new window'
        );
      }

      if (openInNewWindow) {
        const width = Math.min(window.innerWidth * 0.95, 1600);
        const height = Math.min(window.innerHeight * 0.95, 1200);
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        // const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;

        // isoloating child window in more secure and intensive way
        const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,noopener,noreferrer`;
        window.open(url, '_blank', features);
      }
    }
  } catch (error) {
    console.error('while opening single 3D :', error);
  }
};

const captureViewerportsInitialScrollInfoMap = (
  viewportGridService,
  cornerstoneViewportService
) => {
  try {
    const viewerportsIntialScrollInfoMap = new Map();

    const availableViewports = viewportGridService.getState().viewports;

    if (!availableViewports?.size) {
      return;
    }

    const availableViewportsKeys = [...availableViewports.keys()];

    availableViewportsKeys.forEach(keyId => {
      const viewerportInfo = cornerstoneViewportService.getViewportInfo(keyId);

      const renderingEngine = getRenderingEngine(viewerportInfo?.renderingEngineId);
      const tViewerport = renderingEngine.getViewport(viewerportInfo.viewportId);
      const currentIndex = tViewerport.getCurrentImageIdIndex();

      viewerportsIntialScrollInfoMap.set(keyId, {
        initialScrollIndex: currentIndex,
      });
    });

    window._viewerports_initial_scroll_info_map = viewerportsIntialScrollInfoMap;
  } catch (error) {
    console.error('while capturing initial scroll index map', error);
  }
};

export function useToolbar({ servicesManager, buttonSection = 'primary' }: withAppTypes) {
  const { toolbarService, viewportGridService, cornerstoneViewportService, displaySetService } =
    servicesManager.services;
  const { EVENTS } = toolbarService;

  const [toolbarButtons, setToolbarButtons] = useState(
    toolbarService.getButtonSection(buttonSection)
  );

  // Callback function for handling toolbar interactions
  const onInteraction = useCallback(
    args => {
      // Toggle reference lines button for ViewportSync and ImageSliceSync
      if (['ManualImageSliceSync', 'ImageSliceSync']?.includes(args?.itemId)) {
        captureViewerportsInitialScrollInfoMap(viewportGridService, cornerstoneViewportService);
        const referenceLinesBtn = document.body.querySelector('[data-cy="ReferenceLines"]');

        if (referenceLinesBtn) {
          referenceLinesBtn?.click();
        }
      }

      const openMprInNewWindow = window?.config?.openMprInNewWindow ?? false;
      const open3DInNewWindow = window?.config?.open3DInNewWindow ?? false;

      const isMPR = utils?.isMprTrue();
      const isOnly3D = utils?.isOnly3DTrue();

      /**
       * NOTE:
       *  Handle MPR button click,
       *  To avoid multi loop of mpr execution, we kept isMPR check,
       */
      if (openMprInNewWindow === true && !isMPR && !isOnly3D && args?.itemId === 'MPR') {
        handleSingleMPR(viewportGridService, displaySetService, openMprInNewWindow);
        return;
      }

      /**
       * NOTE:
       *  Handle 3D button click,
       *  To avoid multi loop of 3D execution, we kept isOnly3D check,
       */
      if (open3DInNewWindow === true && !isOnly3D && !isMPR && args?.itemId === '3D') {
        handleSingle3D(viewportGridService, displaySetService, open3DInNewWindow);
        return;
      }

      const viewportId = viewportGridService.getActiveViewportId();
      const refreshProps = {
        viewportId,
      };
      toolbarService.recordInteraction(args, {
        refreshProps,
      });
    },
    [toolbarService, viewportGridService]
  );

  // Effect to handle toolbar modification events
  useEffect(() => {
    const handleToolbarModified = () => {
      setToolbarButtons(toolbarService.getButtonSection(buttonSection));
    };

    const subs = [EVENTS.TOOL_BAR_MODIFIED, EVENTS.TOOL_BAR_STATE_MODIFIED].map(event => {
      return toolbarService.subscribe(event, handleToolbarModified);
    });

    return () => {
      subs.forEach(sub => sub.unsubscribe());
    };
  }, [toolbarService]);

  // Effect to handle active viewportId change event
  useEffect(() => {
    const events = [
      viewportGridService.EVENTS.ACTIVE_VIEWPORT_ID_CHANGED,
      viewportGridService.EVENTS.VIEWPORTS_READY,
      viewportGridService.EVENTS.LAYOUT_CHANGED,
    ];

    const subscriptions = events.map(event => {
      return viewportGridService.subscribe(event, ({ viewportId }) => {
        viewportId = viewportId || viewportGridService.getActiveViewportId();
        toolbarService.refreshToolbarState({ viewportId });
      });
    });

    return () => subscriptions.forEach(sub => sub.unsubscribe());
  }, [viewportGridService, toolbarService]);

  return { toolbarButtons, onInteraction };
}
