import { metaData, registerImageLoader, type Types } from '@cornerstonejs/core';

import { loadImage } from './telefiImageLoader';
import metaDataProvider from '@cornerstonejs/dicom-image-loader/wadouri/metaData/metaDataProvider';

export default function (): void {
  console.log('registering telefi loader');
  // register dicomweb and wadouri image loader prefixes
  registerImageLoader('telefi', loadImage as unknown as Types.ImageLoaderFn);

  // add wadouri metadata provider
  metaData.addProvider(metaDataProvider);
}
