import { DataSet } from 'dicom-parser';
import { createImage } from '@cornerstonejs/dicom-image-loader';
import { Types } from '@cornerstonejs/core';
import { dataSetCacheManager } from '@cornerstonejs/dicom-image-loader/wadouri';
import {
  DICOMLoaderIImage,
  DICOMLoaderImageOptions,
} from '@cornerstonejs/dicom-image-loader/types';
import { ImageQualityStatus } from '@cornerstonejs/core/enums';
import xhrRequest from './xhrRequest';
import getPixelData from './getPixelData';

export interface CornerstoneImageUrl {
  scheme: string;
  url: string;
  frame: number;
  pixelDataFrame: number;
}

export type LoadRequestFunction = (
  url: string,
  imageId: string,
  ...args: unknown[]
) => Promise<ArrayBuffer>;

function parseImageId(imageId: string): CornerstoneImageUrl {
  // build a url by parsing out the url scheme and frame index from the imageId
  const firstColonIndex = imageId.indexOf(':');

  let url = imageId.substring(firstColonIndex + 1);
  const frameIndex = url.indexOf('frame=');

  let frame;

  if (frameIndex !== -1) {
    const frameStr = url.substring(frameIndex + 6);

    frame = parseInt(frameStr, 10);
    url = url.substring(0, frameIndex - 1);
  }

  const scheme = imageId.substring(0, firstColonIndex);
  /**
   * Why we adjust frameNumber? since in the above we are extracting the
   * frame number from the imageId (from the metadata), and the frame number
   * starts from 1, but in the loader which uses the dicomParser
   * the frame number starts from 0.
   */

  const adjustedFrame = frame !== undefined ? frame - 1 : undefined;

  return {
    scheme,
    url,
    frame,
    pixelDataFrame: adjustedFrame,
  };
}

// add a decache callback function to clear out our dataSetCacheManager
function addDecache(imageLoadObject: Types.IImageLoadObject, imageId: string) {
  imageLoadObject.decache = function () {
    // console.log('decache');
    const parsedImageId = parseImageId(imageId);

    dataSetCacheManager.unload(parsedImageId.url);
  };
}

function loadImageFromPromise(
  dataSetPromise: Promise<DataSet>,
  imageId: string,
  frame = 0,
  sharedCacheKey: string,
  options: DICOMLoaderImageOptions,
  callbacks?: {
    imageDoneCallback: (image: DICOMLoaderIImage) => void;
  }
): Types.IImageLoadObject {
  const start = new Date().getTime();
  const imageLoadObject: Types.IImageLoadObject = {
    cancelFn: undefined,
    promise: undefined,
  };

  imageLoadObject.promise = new Promise((resolve, reject) => {
    dataSetPromise.then(
      (dataSet /* , xhr*/) => {
        const pixelData = getPixelData(dataSet, frame);
        const transferSyntax = dataSet.string('x00020010');
        const loadEnd = new Date().getTime();
        const imagePromise = createImage(imageId, pixelData, transferSyntax, options);

        addDecache(imageLoadObject, imageId);

        imagePromise.then(
          image => {
            image = image as DICOMLoaderIImage;
            image.data = dataSet;
            image.sharedCacheKey = sharedCacheKey;
            const end = new Date().getTime();

            image.loadTimeInMS = loadEnd - start;
            image.totalTimeInMS = end - start;
            image.imageQualityStatus = ImageQualityStatus.FULL_RESOLUTION;
            if (callbacks !== undefined && callbacks.imageDoneCallback !== undefined) {
              callbacks.imageDoneCallback(image);
            }
            resolve(image);
          },
          function (error) {
            // Reject the error, and the dataSet
            reject({
              error,
              dataSet,
            });
          }
        );
      },
      function (error) {
        // Reject the error
        reject({
          error,
        });
      }
    );
  });

  return imageLoadObject;
}

function loadImageFromDataSet(
  dataSet,
  imageId: string,
  frame = 0,
  sharedCacheKey: string,
  options
): Types.IImageLoadObject {
  const start = new Date().getTime();

  const promise = new Promise<DICOMLoaderIImage | Types.IImageFrame>((resolve, reject) => {
    const loadEnd = new Date().getTime();

    let imagePromise: Promise<DICOMLoaderIImage | Types.IImageFrame>;

    try {
      const pixelData = getPixelData(dataSet, frame);
      const transferSyntax = dataSet.string('x00020010');

      imagePromise = createImage(imageId, pixelData, transferSyntax, options);
    } catch (error) {
      // Reject the error, and the dataSet
      reject({
        error,
        dataSet,
      });

      return;
    }

    imagePromise.then(image => {
      image = image as DICOMLoaderIImage;

      image.data = dataSet;
      image.sharedCacheKey = sharedCacheKey;
      const end = new Date().getTime();

      image.loadTimeInMS = loadEnd - start;
      image.totalTimeInMS = end - start;
      image.imageQualityStatus = ImageQualityStatus.FULL_RESOLUTION;
      resolve(image);
    }, reject);
  });

  return {
    promise: promise as Promise<Types.IImage>,
    cancelFn: undefined,
  };
}

function getLoaderForScheme(scheme: string): LoadRequestFunction {
  if (scheme === 'telefi') {
    return xhrRequest as LoadRequestFunction;
  }
  return undefined;
}

export function loadImage(
  imageId: string,
  options: DICOMLoaderImageOptions = {}
): Types.IImageLoadObject {
  console.log('telefi loader started');
  const parsedImageId = parseImageId(imageId);

  options = Object.assign({}, options);

  // IMPORTANT: if you have a custom loader that you want to use for a specific
  // scheme, you should create your own loader and register it with the scheme
  // in the image loader, and NOT just pass it in as an option. This is because
  // the scheme is used to determine the loader to use and is more maintainable

  // The loader isn't transferable, so ensure it is deleted
  delete options.loader;

  // The options might have a loader above, but it is a loader into the cache,
  // so not the scheme loader, which is separate and defined by the scheme here
  const schemeLoader = getLoaderForScheme(parsedImageId.scheme);

  if (!schemeLoader) {
    throw new Error(`No loader for scheme: ${parsedImageId.scheme}`);
  }

  // if the dataset for this url is already loaded, use it, in case of multiframe
  // images, we need to extract the frame pixelData from the dataset although the
  // image is loaded
  if (dataSetCacheManager.isLoaded(parsedImageId.url)) {
    /**
     * @todo The arguments to the dataSetCacheManager below are incorrect.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataSet: DataSet = (dataSetCacheManager as any).get(
      parsedImageId.url,
      schemeLoader,
      imageId
    );

    return loadImageFromDataSet(
      dataSet,
      imageId,
      parsedImageId.pixelDataFrame,
      parsedImageId.url,
      options
    );
  }

  // load the dataSet via the dataSetCacheManager
  const dataSetPromise = dataSetCacheManager.load(parsedImageId.url, schemeLoader, imageId);

  console.log('telefi loader finished');

  return loadImageFromPromise(
    dataSetPromise,
    imageId,
    parsedImageId.frame,
    parsedImageId.url,
    options
  );
}
