export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams;
};

export const getSeriesUidFromSeachParams = () => {
  const searchParams = getSearchParams();
  const seriesUid = searchParams.get('seriesUid');
  return seriesUid;
};

export const getSeriesNumberFromSearchParams = () => {
  const searchParams = getSearchParams();
  const seriesNumber = searchParams.get('seriesNumber');
  return seriesNumber;
};

// export const getDecodedParamsQuery = () => {
//   const searchParams = getSearchParams();
//   const params = searchParams.get('params') || btoa(JSON.stringify({}));
//   const decodedParams = JSON.parse(atob(params));
//   return decodedParams;
// };

export const isMprTrue = () => {
  const searchParams = getSearchParams();
  const mpr = searchParams.get('mpr');
  return mpr === 'true' ? true : false;
};

export const isOnly3DTrue = () => {
  const searchParams = getSearchParams();
  const only3D = searchParams.get('only3D');
  return only3D === 'true' ? true : false;
};

export const isHideMpr = () => {
  const searchParams = getSearchParams();
  const hideMpr = searchParams.get('hideMpr');
  return hideMpr === 'true' ? true : false;
};

export const isHide3D = () => {
  const searchParams = getSearchParams();
  const hide3D = searchParams.get('hide3D');
  return hide3D === 'true' ? true : false;
};

export const isHideLayout = () => {
  const searchParams = getSearchParams();
  const hideLayout = searchParams.get('hideLayout');
  return hideLayout === 'true' ? true : false;
};

export const isHideDownloadButton = () => {
  const searchParams = getSearchParams();
  return searchParams.get('showDownloadButton') === 'false';
};

export const isHidePatientName = () => {
  const searchParams = getSearchParams();
  return searchParams.get('showPatientName') === 'false';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructSearchParams = (params: Record<string, any>) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value.toString());
  });

  return searchParams;
};
