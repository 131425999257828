import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ThumbnailListV2 } from '../ThumbnailListV2';
import { Icon, StudyItem } from '@ohif/ui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../Accordion';

const getTrackedSeries = displaySets => {
  let trackedSeries = 0;
  displaySets.forEach(displaySet => {
    if (displaySet.isTracked) {
      trackedSeries++;
    }
  });

  return trackedSeries;
};

const StudyItemV2 = ({
  date,
  description,
  numInstances,
  modalities,
  isActive,
  onClick,
  isExpanded,
  displaySets,
  activeDisplaySetInstanceUIDs,
  onClickThumbnail,
  onDoubleClickThumbnail,
  onClickUntrack,
  viewPreset = 'thumbnails',
  onThumbnailContextMenu,
  isLinkedStudy,
  linkedStudyIdentifierHexColor,
}: withAppTypes) => {
  return (
    <>
      {/* {window.innerWidth >= 768 && (
        <StudyItem
          date={date}
          description={description}
          numInstances={numInstances}
          modalities={modalities}
          trackedSeries={getTrackedSeries(displaySets)}
          isActive={isExpanded}
          onClick={onClick}
          data-cy="thumbnail-list"
        />
      )} */}
      <Accordion
        type="single"
        collapsible
        onClick={onClick}
        onKeyDown={() => {}}
        className="flex-shrink-0"
        role="button"
        tabIndex={0}
        defaultValue={isActive ? 'study-item' : undefined}
      >
        <AccordionItem value="study-item">
          <AccordionTrigger
            className={classnames('bg-popover rounded bg-gray-800 hover:bg-gray-700')}
          >
            <div className="flex h-[40px] flex-1 flex-row">
              <div className="flex w-full flex-col items-start justify-between">
                <div className="flex w-full items-center justify-between text-[12px]">
                  <div className="text-white">{date}</div>
                  {isLinkedStudy && linkedStudyIdentifierHexColor && (
                    <span
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: `${linkedStudyIdentifierHexColor}`,
                        borderRadius: '50%',
                        marginRight: '5px',
                        alignSelf: 'center',
                        justifySelf: 'end',
                      }}
                    />
                  )}
                  {/* <div className="text-muted-foreground h-[18px] max-w-[100px] overflow-hidden truncate whitespace-nowrap">
                    {description}
                  </div> */}
                </div>
                <div className="mr-2 flex w-full flex-row items-start justify-between gap-2 text-[12px] text-gray-400">
                  <div className="flex flex-grow items-center justify-start">
                    <div className="max-w-[30px] overflow-hidden text-ellipsis">{modalities}</div>
                    <span> - </span>
                    <div>{numInstances}</div>
                  </div>
                  {isLinkedStudy && (
                    <div className="flex w-auto flex-grow-0">
                      <Icon
                        style={{ color: '#0088ff', width: '15px', height: '15px' }}
                        name={'link'}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent
            onClick={event => {
              event.stopPropagation();
            }}
          >
            {isExpanded && displaySets && (
              <ThumbnailListV2
                thumbnails={displaySets}
                activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
                onThumbnailClick={onClickThumbnail}
                onThumbnailDoubleClick={onDoubleClickThumbnail}
                onClickUntrack={onClickUntrack}
                viewPreset={viewPreset}
                onThumbnailContextMenu={onThumbnailContextMenu}
              />
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};

StudyItemV2.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  displaySets: PropTypes.array,
  activeDisplaySetInstanceUIDs: PropTypes.array,
  onClickThumbnail: PropTypes.func,
  onDoubleClickThumbnail: PropTypes.func,
  onClickUntrack: PropTypes.func,
  viewPreset: PropTypes.string,
};

export { StudyItemV2 };
