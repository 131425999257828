import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Icons,
  Button,
} from '../';

import NavBar from '../NavBar';

// Todo: we should move this component to composition and remove props base

interface HeaderProps {
  children?: ReactNode;
  menuOptions: Array<{
    title: string;
    icon?: string;
    onClick: () => void;
  }>;
  isReturnEnabled?: boolean;
  onClickReturnButton?: () => void;
  isSticky?: boolean;
  WhiteLabeling?: {
    createLogoComponentFn?: (React: any, props: any) => ReactNode;
  };
  PatientInfo?: ReactNode;
  Secondary?: ReactNode;
}

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  PatientInfo,
  Secondary,
  ...props
}: HeaderProps): ReactNode {
  const { t } = useTranslation('Header');

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  if (window.innerWidth <= 768) {
    return (
      <NavBar
        isSticky={isSticky}
        {...props}
      >
        <div className="flex h-fit min-h-[48px] items-center justify-between">
          {/* <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center"> */}
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            // onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {/* {isReturnEnabled && <Icons.ChevronPatient className="text-primary-active w-8" />} */}
            <div className="ml-1">
              {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Icons.OHIFLogo />}
            </div>
          </div>
          {/* </div> */}
          <div className="">{Secondary}</div>
          <div className="">
            <div className="flex items-center justify-center space-x-2">{children}</div>
          </div>
          <div className="flex w-10 select-none items-center">
            {/* {PatientInfo}
            <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
            <div className="flex-shrink-0">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-primary-active hover:bg-primary-dark mt-2 h-full w-full"
                  >
                    <Icons.GearSettings />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {menuOptions.map((option, index) => {
                    const IconComponent = option.icon
                      ? Icons[option.icon as keyof typeof Icons]
                      : null;
                    return (
                      <DropdownMenuItem
                        key={index}
                        onSelect={option.onClick}
                        className="flex items-center gap-2 py-2"
                      >
                        {IconComponent && (
                          <span className="flex h-4 w-4 items-center justify-center">
                            <IconComponent className="h-full w-full" />
                          </span>
                        )}
                        <span className="flex-1">{option.title}</span>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div> */}
          </div>
        </div>
      </NavBar>
    );
  }

  return (
    <NavBar
      isSticky={isSticky}
      {...props}
    >
      <div className="flex h-fit min-h-[48px] items-center justify-between">
        <div
          className={classNames(
            'mr-3 inline-flex flex-shrink-0 items-center',
            isReturnEnabled && 'cursor-pointer'
          )}
          // onClick={onClickReturn}
          data-cy="return-to-work-list"
        >
          {/* {isReturnEnabled && <Icons.ChevronPatient className="text-primary-active w-8" />} */}
          <div className="ml-1">
            {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Icons.OHIFLogo />}
          </div>
          <div className="ml-1">{PatientInfo}</div>
        </div>
        <div className="flex items-center">{Secondary}</div>
        <div className="flex w-full flex-shrink items-center justify-center space-x-1">
          {children}
        </div>
        <div className="flex w-10 items-center">
          {/* {PatientInfo}
          <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
          <div className="flex-shrink-0">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-primary-active hover:bg-primary-dark mt-2 h-full w-full"
                >
                  <Icons.GearSettings />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {menuOptions.map((option, index) => {
                  const IconComponent = option.icon
                    ? Icons[option.icon as keyof typeof Icons]
                    : null;
                  return (
                    <DropdownMenuItem
                      key={index}
                      onSelect={option.onClick}
                      className="flex items-center gap-2 py-2"
                    >
                      {IconComponent && (
                        <span className="flex h-4 w-4 items-center justify-center">
                          <IconComponent className="h-full w-full" />
                        </span>
                      )}
                      <span className="flex-1">{option.title}</span>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div> */}
        </div>
      </div>
    </NavBar>
  );

  // original style
  // return (
  //   <NavBar
  //     isSticky={isSticky}
  //     {...props}
  //   >
  //     <div className="relative h-[48px] items-center">
  //       <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center">
  //         <div
  //           className={classNames(
  //             'mr-3 inline-flex items-center',
  //             isReturnEnabled && 'cursor-pointer'
  //           )}
  //           onClick={onClickReturn}
  //           data-cy="return-to-work-list"
  //         >
  //           {isReturnEnabled && <Icons.ChevronPatient className="text-primary-active w-8" />}
  //           <div className="ml-1">
  //             {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Icons.OHIFLogo />}
  //           </div>
  //         </div>
  //       </div>
  //       <div className="absolute top-1/2 left-[250px] h-8 -translate-y-1/2">{Secondary}</div>
  //       <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
  //         <div className="flex items-center justify-center space-x-2">{children}</div>
  //       </div>
  //       <div className="absolute right-0 top-1/2 flex -translate-y-1/2 select-none items-center">
  //         {PatientInfo}
  //         <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
  //         <div className="flex-shrink-0">
  //           <DropdownMenu>
  //             <DropdownMenuTrigger asChild>
  //               <Button
  //                 variant="ghost"
  //                 size="icon"
  //                 className="text-primary-active hover:bg-primary-dark mt-2 h-full w-full"
  //               >
  //                 <Icons.GearSettings />
  //               </Button>
  //             </DropdownMenuTrigger>
  //             <DropdownMenuContent align="end">
  //               {menuOptions.map((option, index) => {
  //                 const IconComponent = option.icon
  //                   ? Icons[option.icon as keyof typeof Icons]
  //                   : null;
  //                 return (
  //                   <DropdownMenuItem
  //                     key={index}
  //                     onSelect={option.onClick}
  //                     className="flex items-center gap-2 py-2"
  //                   >
  //                     {IconComponent && (
  //                       <span className="flex h-4 w-4 items-center justify-center">
  //                         <IconComponent className="h-full w-full" />
  //                       </span>
  //                     )}
  //                     <span className="flex-1">{option.title}</span>
  //                   </DropdownMenuItem>
  //                 );
  //               })}
  //             </DropdownMenuContent>
  //           </DropdownMenu>
  //         </div>
  //       </div>
  //     </div>
  //   </NavBar>
  // );
}

export default Header;
