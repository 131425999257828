import type { DataSet } from 'dicom-parser';
import unpackBinaryFrame from './unpackBinaryFrame';

/**
 * Function to deal with extracting an image frame from an encapsulated data set.
 */

// function getUncompressedImageFrame(dataSet: DataSet, frameIndex: number): Uint8Array {
//   const pixelDataElement = dataSet.elements.x7fe00010 || dataSet.elements.x7fe00008;
//   const bitsAllocated = dataSet.uint16('x00280100');
//   const rows = dataSet.uint16('x00280010');
//   const columns = dataSet.uint16('x00280011');

//   let samplesPerPixel = dataSet.uint16('x00280002');

//   /**
//    * From: http://dicom.nema.org/medical/dicom/current/output/chtml/part03/sect_C.7.6.3.html
//    *
//    * Though the chrominance channels are downsampled, there are still nominally
//    * three channels, hence Samples per Pixel (0028,0002) has a value of 3, not
//    * 2. I.e., for pixel data in a Native (uncompressed) format, the Value Length
//    * of Pixel Data (7FE0,0010) is not:
//    *
//    * Rows (0028,0010) * Columns (0028,0011) * Number of Frames (0028,0008) *
//    * Samples per Pixel (0028,0002) * (⌊(Bits Allocated (0028,0100)-1)/8⌋+1)
//    *
//    * padded to an even length, as it would otherwise be, but rather is:
//    *
//    * Rows (0028,0010) * Columns (0028,0011) * Number of Frames (0028,0008) * 2 *
//    * (⌊(Bits Allocated (0028,0100)-1)/8⌋+1)
//    *
//    * padded to an even length.
//    */
//   const photometricInterpretation = dataSet.string('x00280004');

//   if (photometricInterpretation === 'YBR_FULL_422') {
//     samplesPerPixel = 2;
//     console.warn(
//       `Using SamplesPerPixel of 2 for YBR_FULL_422 photometric interpretation.
//       See http://dicom.nema.org/medical/dicom/current/output/chtml/part03/sect_C.7.6.3.html for more information.`
//     );
//   }

//   const pixelDataOffset = pixelDataElement.dataOffset;
//   const pixelsPerFrame = rows * columns * samplesPerPixel;

//   let frameOffset;

//   if (bitsAllocated === 8) {
//     frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame;
//     if (frameOffset >= dataSet.byteArray.length) {
//       throw new Error('frame exceeds size of pixelData');
//     }

//     return new Uint8Array(
//       dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame)
//     );
//   } else if (bitsAllocated === 16) {
//     frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 2;
//     if (frameOffset >= dataSet.byteArray.length) {
//       throw new Error('frame exceeds size of pixelData');
//     }

//     return new Uint8Array(
//       dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame * 2)
//     );
//   } else if (bitsAllocated === 1) {
//     frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 0.125;
//     if (frameOffset >= dataSet.byteArray.length) {
//       throw new Error('frame exceeds size of pixelData');
//     }

//     return unpackBinaryFrame(dataSet.byteArray, frameOffset, pixelsPerFrame);
//   } else if (bitsAllocated === 32) {
//     frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 4;
//     if (frameOffset >= dataSet.byteArray.length) {
//       throw new Error('frame exceeds size of pixelData');
//     }

//     return new Uint8Array(
//       dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame * 4)
//     );
//   }

//   throw new Error('unsupported pixel format');
// }

const MAX_SIZE = 2000;

function normalizeTo8Bit(data: Uint8Array | Uint16Array): Uint8Array {
  let min = Infinity;
  let max = -Infinity;

  for (let i = 0; i < data.length; i++) {
    const val = data[i];
    min = Math.min(min, val);
    max = Math.max(max, val);
  }

  const range = max - min || 1;
  const out = new Uint8Array(data.length);
  for (let i = 0; i < data.length; i++) {
    out[i] = Math.round(((data[i] - min) / range) * 255);
  }

  return out;
}

function resizePixelData(
  pixelData: Uint8Array,
  width: number,
  height: number,
  newWidth: number,
  newHeight: number
): Uint8Array {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  const imgData = ctx.createImageData(width, height);

  for (let i = 0; i < pixelData.length; i++) {
    const val = pixelData[i];
    imgData.data[i * 4 + 0] = val;
    imgData.data[i * 4 + 1] = val;
    imgData.data[i * 4 + 2] = val;
    imgData.data[i * 4 + 3] = 255;
  }

  ctx.putImageData(imgData, 0, 0);

  const resizedCanvas = document.createElement('canvas');
  resizedCanvas.width = newWidth;
  resizedCanvas.height = newHeight;

  const resizedCtx = resizedCanvas.getContext('2d');
  resizedCtx.drawImage(canvas, 0, 0, newWidth, newHeight);

  const resizedImageData = resizedCtx.getImageData(0, 0, newWidth, newHeight);
  const resizedGrayscale = new Uint8Array(newWidth * newHeight);

  for (let i = 0; i < resizedGrayscale.length; i++) {
    resizedGrayscale[i] = resizedImageData.data[i * 4]; // Use R channel
  }

  return resizedGrayscale;
}

function getUncompressedImageFrame(dataSet: DataSet, frameIndex: number): Uint8Array {
  const pixelDataElement = dataSet.elements.x7fe00010 || dataSet.elements.x7fe00008;
  const bitsAllocated = dataSet.uint16('x00280100');
  const rows = dataSet.uint16('x00280010');
  const columns = dataSet.uint16('x00280011');
  const photometricInterpretation = dataSet.string('x00280004');
  let samplesPerPixel = dataSet.uint16('x00280002') || 1;

  if (photometricInterpretation === 'YBR_FULL_422') {
    samplesPerPixel = 2;
  }

  const pixelDataOffset = pixelDataElement.dataOffset;
  const pixelsPerFrame = rows * columns * samplesPerPixel;

  let frameOffset: number;

  if (bitsAllocated === 8) {
    frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame;
    const raw = new Uint8Array(
      dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame)
    );

    if (rows > MAX_SIZE || columns > MAX_SIZE) {
      const scaleFactor = MAX_SIZE / Math.max(rows, columns);
      const newW = Math.round(columns * scaleFactor);
      const newH = Math.round(rows * scaleFactor);
      return resizePixelData(raw, columns, rows, newW, newH);
    }

    return raw;
  }

  if (bitsAllocated === 16) {
    frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 2;
    const raw16 = new Uint16Array(
      dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame * 2)
    );
    const normalized = normalizeTo8Bit(raw16);

    if (rows > MAX_SIZE || columns > MAX_SIZE) {
      const scaleFactor = MAX_SIZE / Math.max(rows, columns);
      const newW = Math.round(columns * scaleFactor);
      const newH = Math.round(rows * scaleFactor);
      return resizePixelData(normalized, columns, rows, newW, newH);
    }

    return normalized;
  }

  if (bitsAllocated === 1) {
    frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 0.125;
    return unpackBinaryFrame(dataSet.byteArray, frameOffset, pixelsPerFrame);
  }

  if (bitsAllocated === 32) {
    frameOffset = pixelDataOffset + frameIndex * pixelsPerFrame * 4;
    return new Uint8Array(
      dataSet.byteArray.buffer.slice(frameOffset, frameOffset + pixelsPerFrame * 4)
    );
  }

  throw new Error('Unsupported bitsAllocated: ' + bitsAllocated);
}

export default getUncompressedImageFrame;
