import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '../IconButton';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const considerAsToggleBtn = ['MPR', 'ImageSliceSync', 'ReferenceLines', '3D'];

const ToolbarButton = ({
  id,
  icon,
  label,
  commands,
  onInteraction,
  dropdownContent = null,
  //
  className,
  disabled,
  disabledText,
  size,
  toolTipClassName,
  disableToolTip = false,
  ...rest
  //
}) => {
  const [isToggleBtn, setIsToggleBtn] = useState<boolean>(false);
  const [isActiveToggle, setIsActiveToggle] = useState<boolean>(false);

  const shouldShowDropdown = !!dropdownContent;
  const iconEl = icon ? <Icon name={icon} /> : <div>{label || 'Missing icon and label'}</div>;

  const sizeToUse = size ?? 'toolbar';
  const toolTipClassNameToUse =
    toolTipClassName !== undefined
      ? toolTipClassName
      : sizeToUse === 'toolbar'
        ? 'w-[25px] h-[25px]'
        : 'w-[32px] h-[32px]';

  useEffect(() => {
    if (considerAsToggleBtn.includes(id)) {
      setIsToggleBtn(true);
    }

    return () => setIsToggleBtn(false);
  }, []);

  return (
    <div key={id}>
      <Tooltip
        isSticky={shouldShowDropdown}
        content={shouldShowDropdown ? dropdownContent : label}
        secondaryContent={disabled ? disabledText : null}
        tight={shouldShowDropdown}
        className={toolTipClassNameToUse}
        isDisabled={disableToolTip}
      >
        <IconButton
          size={window.innerWidth <= 768 ? 'small' : 'medium'}
          className={classNames(className, disabled ? 'ohif-disabled' : '')}
          onClick={() => {
            onInteraction({
              itemId: id,
              commands,
            });
            if (isToggleBtn) {
              setIsActiveToggle(!isActiveToggle);
            }
          }}
          name={label}
          key={id}
          id={id}
          {...rest}
        >
          {iconEl}
        </IconButton>
      </Tooltip>
    </div>
  );
};

ToolbarButton.propTypes = {
  /* Influences background/hover styling */
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  commands: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  onInteraction: PropTypes.func,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /** Tooltip content can be replaced for a customized content by passing a node to this value. */
  dropdownContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  size: PropTypes.string,
  toolTipClassName: PropTypes.string,
  disableToolTip: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToolbarButton;
