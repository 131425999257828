import React from 'react';
import PropTypes from 'prop-types';

import { StudyItemV2 } from '../StudyItemV2';
import { StudyBrowserSort } from '../StudyBrowserSort';
import { StudyBrowserViewOptions } from '../StudyBrowserViewOptions';
import { DicomMetadataStore } from '@ohif/core';

const getTrackedSeries = displaySets => {
  let trackedSeries = 0;
  displaySets.forEach(displaySet => {
    if (displaySet.isTracked) {
      trackedSeries++;
    }
  });

  return trackedSeries;
};

const noop = () => {};

const StudyBrowserV2 = ({
  tabs,
  activeTabName,
  expandedStudyInstanceUIDs,
  onClickTab = noop,
  onClickStudy = noop,
  onClickThumbnail = noop,
  onDoubleClickThumbnail = noop,
  onClickUntrack = noop,
  activeDisplaySetInstanceUIDs,
  servicesManager,
  showSettings,
  viewPresets,
  onThumbnailContextMenu,
}: withAppTypes) => {
  const getTabContent = () => {
    const tabData = tabs.find(tab => tab.name === activeTabName);
    const viewPreset = viewPresets
      ? viewPresets.filter(preset => preset.selected)[0]?.id
      : 'thumbnails';

    const linkedStudies = DicomMetadataStore?.getLinkedStudies();

    // Non linked studies at the top
    if (tabData?.studies?.length && linkedStudies?.length) {
      tabData?.studies?.sort((a, b) => {
        const isA = linkedStudies?.includes(a?.studyInstanceUid);
        const isB = linkedStudies?.includes(b?.studyInstanceUid);
        if (isA && isB) {
          return 0;
        }
        if (isA) {
          return 1;
        }
        if (isB) {
          return -1;
        }
        return 0;
      });
    }

    return tabData.studies.map(data => {
      const { studyInstanceUid, date, description, numInstances, modalities, displaySets } = data;

      const isExpanded = expandedStudyInstanceUIDs.includes(studyInstanceUid);
      const linkedStudyIdentifierHexColor =
        DicomMetadataStore?.getLinkedStudyIdentifierHexColorFromStudyInstanceUid(studyInstanceUid);
      const isLinkedStudy = linkedStudies?.includes(studyInstanceUid);
      return (
        <React.Fragment key={studyInstanceUid}>
          <StudyItemV2
            date={date}
            description={description}
            numInstances={numInstances}
            isExpanded={isExpanded}
            displaySets={displaySets}
            modalities={modalities}
            trackedSeries={getTrackedSeries(displaySets)}
            isActive={isExpanded}
            onClick={() => {
              onClickStudy(studyInstanceUid);
            }}
            onClickThumbnail={onClickThumbnail}
            onDoubleClickThumbnail={onDoubleClickThumbnail}
            onClickUntrack={onClickUntrack}
            activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
            data-cy="thumbnail-list"
            viewPreset={viewPreset}
            onThumbnailContextMenu={onThumbnailContextMenu}
            isLinkedStudy={isLinkedStudy}
            linkedStudyIdentifierHexColor={linkedStudyIdentifierHexColor}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <div
      className="ohif-scrollbar invisible-scrollbar bg-bkg-low flex flex-1 flex-col gap-[4px] overflow-auto pt-px"
      data-cy={'studyBrowser-panel'}
    >
      {showSettings && (
        <div className="w-100 bg-bkg-low flex h-[48px] items-center justify-center gap-[10px] px-[8px] py-[10px]">
          <>
            <StudyBrowserViewOptions
              tabs={tabs}
              onSelectTab={onClickTab}
              activeTabName={activeTabName}
            />
            <StudyBrowserSort servicesManager={servicesManager} />
          </>
        </div>
      )}
      {getTabContent()}
    </div>
  );
};

StudyBrowserV2.propTypes = {
  onClickTab: PropTypes.func.isRequired,
  onClickStudy: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onDoubleClickThumbnail: PropTypes.func,
  onClickUntrack: PropTypes.func,
  activeTabName: PropTypes.string.isRequired,
  expandedStudyInstanceUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          studyInstanceUid: PropTypes.string.isRequired,
          date: PropTypes.string,
          numInstances: PropTypes.number,
          modalities: PropTypes.string,
          description: PropTypes.string,
          displaySets: PropTypes.arrayOf(
            PropTypes.shape({
              displaySetInstanceUID: PropTypes.string.isRequired,
              imageSrc: PropTypes.string,
              imageAltText: PropTypes.string,
              seriesDate: PropTypes.string,
              seriesNumber: PropTypes.any,
              numInstances: PropTypes.number,
              description: PropTypes.string,
              componentType: PropTypes.oneOf(['thumbnail', 'thumbnailTracked', 'thumbnailNoImage'])
                .isRequired,
              isTracked: PropTypes.bool,
              /**
               * Data the thumbnail should expose to a receiving drop target. Use a matching
               * `dragData.type` to identify which targets can receive this draggable item.
               * If this is not set, drag-n-drop will be disabled for this thumbnail.
               *
               * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
               */
              dragData: PropTypes.shape({
                /** Must match the "type" a dropTarget expects */
                type: PropTypes.string.isRequired,
              }),
            })
          ),
        })
      ).isRequired,
    })
  ),
};

export { StudyBrowserV2 };
