import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ThumbnailV2 } from '../ThumbnailV2';

const ThumbnailListV2 = ({
  thumbnails,
  onThumbnailClick,
  onThumbnailDoubleClick,
  onClickUntrack,
  activeDisplaySetInstanceUIDs = [],
  viewPreset,
  onThumbnailContextMenu,
}: withAppTypes) => {
  // NOTE: copied from our commit history **********************************************************************
  // cviewer custom:
  // commit details:
  //   1. sort thumnails based on modality
  //   2. small UI Change
  // commit hash: 93f2395388cbbf3d74817d60873cf1630f9f1437
  if (thumbnails && Array.isArray(thumbnails) && thumbnails?.length > 0) {
    thumbnails?.sort((a, b) => {
      const modalityA = a.modality || 'ZZZZ'; // 'ZZZZ' will place items without modality at the end
      const modalityB = b.modality || 'ZZZZ';
      return modalityA.localeCompare(modalityB);
    });
    thumbnails?.sort((a, b) => {
      if (a.componentType === 'thumbnailNoImage') {
        return 1;
      }
      if (b.componentType === 'thumbnailNoImage') {
        return -1;
      }
      return a.seriesNumber - b.seriesNumber;
    });
  }

  // ***********************************************************************************************************

  return (
    <div className={`min-h-[${window.innerWidth <= 768 ? 'auto' : '100px'}]`}>
      <div
        id="ohif-thumbnail-list"
        className={
          window.innerWidth <= 768
            ? 'ohif-scrollbar bg-bkg-low flex items-center gap-x-2 overflow-auto'
            : 'ohif-scrollbar bg-bkg-low flex flex-col items-center overflow-auto'
          // : `ohif-scrollbar bg-bkg-low grid grid-cols-1 place-items-center gap-[4px] overflow-y-hidden pt-[4px] pr-[2.5px] pl-[2.5px] pb-[12px]`
        }
        style={{ height: window.innerWidth <= 768 ? '20vh' : 'auto', width: '100%' }}
      >
        {thumbnails.map(
          ({
            displaySetInstanceUID,
            description,
            dragData,
            seriesInstanceUID,
            seriesNumber,
            numInstances,
            loadingProgress,
            modality,
            componentType,
            countIcon,
            isTracked,
            canReject,
            onReject,
            imageSrc,
            messages,
            imageAltText,
            isHydratedForDerivedDisplaySet,
          }) => {
            const isActive = activeDisplaySetInstanceUIDs?.includes(displaySetInstanceUID);
            description = description || 'Desc Not Available';
            return (
              <ThumbnailV2
                key={displaySetInstanceUID}
                displaySetInstanceUID={displaySetInstanceUID}
                dragData={dragData}
                description={description}
                seriesInstanceUID={seriesInstanceUID}
                seriesNumber={seriesNumber}
                numInstances={numInstances || 1}
                countIcon={countIcon}
                imageSrc={imageSrc}
                imageAltText={imageAltText}
                messages={messages}
                isActive={isActive}
                modality={modality}
                viewPreset={componentType === 'thumbnailNoImage' ? 'list' : viewPreset}
                thumbnailType={componentType}
                onClick={() => onThumbnailClick(displaySetInstanceUID)}
                onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                isTracked={isTracked}
                loadingProgress={loadingProgress}
                onClickUntrack={() => onClickUntrack(displaySetInstanceUID)}
                isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
                canReject={canReject}
                onReject={onReject}
                onThumbnailContextMenu={onThumbnailContextMenu}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

ThumbnailListV2.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      displaySetInstanceUID: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      imageAltText: PropTypes.string,
      seriesDate: PropTypes.string,
      seriesNumber: PropTypes.any,
      numInstances: PropTypes.number,
      description: PropTypes.string,
      componentType: PropTypes.any,
      isTracked: PropTypes.bool,
      /**
       * Data the thumbnail should expose to a receiving drop target. Use a matching
       * `dragData.type` to identify which targets can receive this draggable item.
       * If this is not set, drag-n-drop will be disabled for this thumbnail.
       *
       * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
       */
      dragData: PropTypes.shape({
        /** Must match the "type" a dropTarget expects */
        type: PropTypes.string.isRequired,
      }),
    })
  ),
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  onThumbnailClick: PropTypes.func.isRequired,
  onThumbnailDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
  viewPreset: PropTypes.string,
};

export { ThumbnailListV2 };
