import React from 'react';
import Exclamation from '../../../public/assets/exclamation-yellow.png';

export function PageNotFound() {
  return (
    <div className="h-[100vh] w-[100vw] overflow-hidden">
      <div className="flex h-full w-full items-center justify-center gap-2">
        <img
          src={Exclamation}
          alt="exclamation-yellow"
          style={{ height: '20px', width: 'auto' }}
        />
        <h4 className="font-semibold text-white">Page not found</h4>
      </div>
    </div>
  );
}
